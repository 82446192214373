import React from 'react';
import { Link } from 'gatsby';

import { header } from '../translations';

import style from './header.module.css';

const Header = ({ language }) => (
  <header className={style.header}>
    <h1 className={style.mainTitle}>
      <Link className={style.link} to="/">Spicy Strawberry</Link>
    </h1>
    <h2 className={style.subTitle}>{header[language].subtitle}</h2>
  </header>
);

export default Header;
