import React from 'react';
import PropTypes from 'prop-types';
import 'typeface-montserrat';

import useLocalStorage from '../hooks/local-storage';

import './layout.css';

import Header from "./header";

const Layout = ({ children }) => {
  const [language, setLanguage] = useLocalStorage('spicy-lang', 'fr');

  return (
    <>
      <Header language={language} setLanguage={setLanguage} />
      <div className="container">
        <main>{children(language, setLanguage)}</main>
      </div>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
