import React, { Fragment, useState } from 'react';
import loadable from '@loadable/component';

import Layout from '../components/layout';
import MainImage from '../components/main-image';
import SEO from '../components/seo';
import Footer from '../components/footer';

import { main } from '../translations';

import image1 from '../images/carousel-1.jpg';
import image2 from '../images/carousel-2.jpg';
import image4 from '../images/carousel-4.jpg';
import image6 from '../images/carousel-6.jpg';
import image8 from '../images/carousel-8.jpg';
import image11 from '../images/carousel-11.jpg';

import preview1 from '../images/preview-1.jpg';
import preview2 from '../images/preview-2.jpg';
import preview4 from '../images/preview-4.jpg';
import preview6 from '../images/preview-6.jpg';
import preview8 from '../images/preview-8.jpg';
import preview11 from '../images/preview-11.jpg';

import style from './index.module.css';

const WrappedViewer = loadable(() => import('react-viewer'));

const IndexPage = () => {
  const [viewer_index, setViewerIndex] = useState(null);
  const images_viewer = [image4, image1, image2, image6, image11, image8].map(image => ({src : image }));
  const previews = [preview4, preview1, preview2, preview6, preview11, preview8].map((preview, idx) => <div className={style.imgWrapper}>
    <div className={style.innerImgContainer}>
      <img src={preview} alt={`preview-${idx}`} onClick={() => setViewerIndex(idx)} />
    </div>
  </div>);

  return <Layout>
    { (language, setLanguage) => (
      <Fragment>
        <SEO title="Spicy Strawberry" />
        <div className={style.mainContainer}>
          <section className={style.left}>
            <div className={style.description}>
              <p>{main[language].text1}</p>
              <p>{main[language].text2}</p>
              <p>{main[language].text3}</p>
              <p>{main[language].text4}</p>
              <p>{main[language].text5}</p>
              <p>{main[language].text6}</p>
              <p>
                {main[language].text9}
                <a href="https://des-heros.fr/">Nous Sommes des Héros</a>
                {main[language].text10}
              </p>
              <Footer language={language} setLanguage={setLanguage} />
            </div>
          </section>
          <section className={style.right}>
            <div className={style.mainImageWrapper}>
              <div className={style.innerContainer}>
                <MainImage />
              </div>
            </div>
            <div className={style.photoList}>
              {previews}
            </div>
          </section>
          <WrappedViewer
            visible={viewer_index !== null}
            activeIndex={viewer_index}
            onClose={() => setViewerIndex(null)}
            onMaskClick={() => setViewerIndex(null)}
            drag={false}
            scalable={false}
            zoomable={false}
            rotatable={false}
            images={images_viewer} />
        </div>
      </Fragment>
    )}
  </Layout>;
};

export default IndexPage;
