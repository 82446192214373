import { useState } from 'react';

export default function useLocalStorage(key, initial_value) {
  const windowGlobal = typeof window !== 'undefined' && window;

  // // Fix for build
  if (!windowGlobal) {
    return ['fr', () => 'fr'];
  }

  const [storage_value, setStorageValue] = useState(() => {
    try {
      const item = windowGlobal.localStorage.getItem(key);

      return item ? JSON.parse(item) : initial_value;
    }
    catch (err) {
      console.error(err);
      return initial_value;
    }
  });

  const setValue = value => {
    try {
      setStorageValue(value);
      windowGlobal.localStorage.setItem(key, JSON.stringify(value));
    }
    catch (err) {
      console.log(err);
    }
  };

  return [storage_value, setValue];
}
