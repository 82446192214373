export const header = {
  fr: {
    subtitle: 'Modèle & Pin-Up Geek'
  },
  en: {
    subtitle: 'Model & Geek Pin- Up'
  }
}

export const main = {
  fr: {
    text1: 'Modèle française habitant à Paris, Spicy Strawberry est une pin-up geek : elle mélange l’esthétique du rétro et du rockabilly avec des références geek comme les jeux vidéo, les comics, les manga, la fantasy et la science-fiction.',
    text2: 'Si vous voyez une pin-up avec une robe Pikachu et un collier Star Wars...pas de doute, c’est elle !',
    text3: 'Pétillante et colorée, Spicy aime jouer les femmes fatales, toujours avec malice et de légèreté.',
    text4: 'Elle a travaillé avec de nombreux photographes européens, comme Carlos Kella, Tigz Rice, Aenia Smith, Christophe Perez, Fairy Decadence et Krystel Marques pour ne citer qu’eux. Elle a également collaboré avec des marques, comme Fillandises et GeekMeMore.',
    text5: 'Spicy a été publiée à l’international: Pure Vintage, Pure Pin- Up Magazine, Rolling Stones, Delicious Dolls Magazine et le célèbre calendrier “Girls & Legendary US Cars” 2021.',
    text6: 'En 2017, elle a également gagné l’émission “Les Reines du Shopping” avec Cristina Cordula sur le thème “Stylée avec une pièce rock”. Les téléspectateurs de M6 ont également pu la voir dans E=M6 avec Mac Lesggy en 2018, dans un reportage sur le jukebox.',
    text9: 'Elle est l’ambassadrice de ',
    text10: ', une marque Française de bijoux haut de gamme qui donne vie aux artefacts légendaires de la pop culture.'
  },
  en: {
    text1: 'French model living in Paris, Spicy Strawberry is a geek pin-up: she mixed rockabilly and retro style with geek references like video games, comics, manga, fantasy and Sci-Fi',
    text2: 'If you see a rockabella with a Pikachu dress and a Star Wars necklace...it’s her !',
    text3: 'Sparking and colorful, Spicy likes to play femme fatale, always with mischief and lightness.',
    text4: 'She worked with several european photographers, for example Carlos Kella, Tigz Rice, Aenia Smith, Christophe Perez, Fairy Decadence and Krystel Marques. She also collaborated with brands, like Fillandises and GeekMeMore.',
    text5: 'Spicy is an international published model : Pure Vintage, Pure Pin-Up Magazine, Rolling Stones Fr, Delicious Dolls Magazine and the famous “Girls & Legendary American Cars” calendar for 2021.',
    text6: 'In 2017, she also won the french TV Show “Les Reines du Shopping” with Cristina Cordula. In 2018, Spicy participate at other french TV Show, E=M6, with Mac Lesggy, in a jukebox reporting.',
    text9: 'She is ambassador for ',
    text10: ', an high-end jewels brand which give life to the legendary artefacts of pop culture.'
  }
}

export const footer = {
  fr: {
    wish: 'Vous souhaitez travailler avec Spicy pour un shooting ou un autre projet ?',
    contact: 'Envoyez-lui un message à ',
    language_select: 'Spicy en français'
  },
  en: {
    wish: 'Wish to work with Spicy for shooting or other project?',
    contact: 'Send her a message at ',
    language_select: 'Spicy in english'
  }
}
