import React from 'react';

import { footer } from '../translations';

import fb from './facebook.png';
import insta from './instagram.png';
import gmail from './gmail.png';
import fr from './fr.png';
import us from './us.png';
import tiktok from './tiktok.png';

import style from './footer.module.css';

const Footer = ({ language, setLanguage }) => (
  <footer>
    <p>
      <span className={style.cta}>{footer[language].wish}</span>
      <span>{footer[language].contact}</span>
      <a href="mailto:spicyyystrawberryyy@gmail.com">
        spicyyystrawberryyy@gmail.com
      </a>
    </p>
    <div className={style.socialLinks}>
      <a href="mailto:spicyyystrawberryyy@gmail.com">
        <img src={gmail} width={40} alt='mail' />
      </a>
      <a href="https://www.facebook.com/spicystrawberryofficiel/">
        <img src={fb} width={40} alt="facebook" />
      </a>
      <a href="https://www.instagram.com/_spicy.strawberry_/">
        <img src={insta} width={40} alt="instagram" />
      </a>
      <a href="https://www.tiktok.com/@_spicy.strawberry_?">
        <img src={tiktok} width={40} alt="tiktok" />
      </a>
    </div>
    <div className={style.languagePicker}>
      {language === 'fr'
        ?
        <button type="button" className={style.language} onClick={() => setLanguage('en')}>
          <span>{footer.en.language_select}</span>
          <img src={us} alt='us-flag' className={style.flag} />
        </button>
        :
        <button type="button" className={style.language} onClick={() => setLanguage('fr')} >
          <span>{footer.fr.language_select}</span>
          <img src={fr} alt='fr-flag' className={style.flag} />
        </button>
      }
    </div>
  </footer>
);

export default Footer;
